var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        { attrs: { prop: "originOrder" } },
        [
          _c("span", [_vm._v("售后订单")]),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.oneOrMore == "1",
                  expression: "form.oneOrMore == '1'"
                }
              ],
              attrs: {
                type: "text",
                icon: "el-icon-circle-plus-outline",
                disabled: _vm.orderBtnDisabled
              },
              on: { click: _vm.showOrderModal }
            },
            [_vm._v("添加订单")]
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                stripe: "",
                size: "mini",
                "element-loading-text": "拼命加载中",
                "row-style": { height: "48px" },
                "cell-style": { padding: "0px" },
                "header-cell-style": { background: "#e5f2ff" },
                "header-cell-class-name": "normal-table-header",
                data: _vm.orderTable
              }
            },
            [
              _vm._l(_vm.orderColumns, function(item) {
                return [
                  item.prop === "payType"
                    ? _c("el-table-column", {
                        key: item.prop,
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: item.label,
                          width: "120px"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.payTypeMap[row.payType]) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : item.prop === "allPay"
                    ? _c("el-table-column", {
                        key: item.prop,
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: item.label
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.allPayMap[row.allPay]) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : item.prop === "seSeQuenName"
                    ? _c("el-table-column", {
                        key: item.prop,
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: item.label,
                          width: "180px",
                          fixed: item.fixed
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(row.seSeQuenName))
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "#337AFF",
                                        "margin-top": "6px"
                                      }
                                    },
                                    [_vm._v(_vm._s(row.commodityName))]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : item.prop === "orderStatus"
                    ? _c(
                        "el-table-column",
                        {
                          key: item.prop,
                          attrs: {
                            "show-overflow-tooltip": true,
                            label: item.label,
                            width: "120px"
                          }
                        },
                        [_vm._v("--")]
                      )
                    : item.prop === "afterSaleTypes"
                    ? _c(
                        "el-table-column",
                        {
                          key: item.prop,
                          attrs: {
                            "show-overflow-tooltip": true,
                            label: item.label
                          }
                        },
                        [_vm._v("--")]
                      )
                    : item.prop === "registDate"
                    ? _c("el-table-column", {
                        key: item.prop,
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: item.label,
                          width: "180px"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatDate")(
                                          row.registDate,
                                          "YYYY-MM-DD HH:mm:ss"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : item.prop === "installmentType"
                    ? _c("el-table-column", {
                        key: item.prop,
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: item.prop,
                          label: item.label,
                          width: item.width
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  row.installmentType != 0
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.installmentTypeMap[
                                              row.installmentType
                                            ]
                                          )
                                        )
                                      ])
                                    : _c("span", [_vm._v("- -")])
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : item.prop === "tradeSerialNumber"
                    ? _c("el-table-column", {
                        key: item.prop,
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: item.prop,
                          label: item.label,
                          width: item.width
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  row.installmentType != 0
                                    ? _c("span", [
                                        _vm._v(_vm._s(row.tradeSerialNumber))
                                      ])
                                    : _c("span", [_vm._v("- -")])
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : item.prop === "customerIdCode"
                    ? _c("el-table-column", {
                        key: item.prop,
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: item.prop,
                          label: item.label,
                          width: item.width,
                          fixed: item.fixed
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("empty")(row[item.prop], "--")
                                      )
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : _c("el-table-column", {
                        key: item.prop,
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: item.prop,
                          label: item.label,
                          width: item.width,
                          fixed: item.fixed
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  [
                                    "prodLineName",
                                    "goodsTypeName",
                                    "priTypeName",
                                    "campusName"
                                  ].includes(item.prop)
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("empty")(
                                              row[item.prop],
                                              "--"
                                            )
                                          )
                                        )
                                      ])
                                    : [
                                        "goodsSpecName",
                                        "goodsCategoryName"
                                      ].includes(item.prop)
                                    ? _c("span", [_vm._v("--")])
                                    : _c("span", [
                                        _vm._v(_vm._s(row[item.prop]))
                                      ])
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                ]
              }),
              _vm.orderTable.length > 1
                ? _c("el-table-column", {
                    key: "action",
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "操作",
                      width: "180",
                      fixed: "right"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.$index !== 0
                                ? _c(
                                    "a",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: { type: "error" },
                                      on: {
                                        click: function($event) {
                                          return _vm.onDeleteRow(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1181556229
                    )
                  })
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }